export const isAndroid = () => {
  if (typeof window !== "undefined") {
    return window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
  }

  return null;
};

export const isIOS = () => {
  if (typeof window !== "undefined") {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(window.navigator.platform) ||
      // iPad on iOS 13 detection
      (window.navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  return null;
};

export const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    this.fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    () => {
      console.log("Async: Copying to clipboard was successful!");
    },
    (err) => {
      console.error("Async: Could not copy text: ", err);
    }
  );
};

export const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

export const onDownloadClick = (store = "") => {
  const isNativeApp = window?.isNativeApp;
  console.log("isNativeApp", isNativeApp);
  if (isNativeApp) {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        action: "closeWebview",
      })
    );
    return;
  }

  const isMobile = "ontouchstart" in window; // likely mobile
  console.log("isMobile", isMobile);

  if (store === "playStore" && !isMobile) {
    window.location.href =
      "https://play.google.com/store/apps/details?id=in.namaspay.app";
  } else if (store === "appStore" && !isMobile) {
    window.location.href =
      "https://apps.apple.com/in/app/namaspay/id6449447184";
  } else if (isMobile) {
    const downloadLink = "https://namaspay.page.link/download";
    window.location.replace(downloadLink);
  } else {
    window.location.href =
      "https://play.google.com/store/apps/details?id=in.namaspay.app";
  }
};

export const onCloseAccount = () => {
  window.location.href =
    "https://mail.google.com/mail/?view=cm&fs=1&to=care@namaspay.in";
};
