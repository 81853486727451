// Libraries
import Head from 'next/head';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from "next/script";
import {DefaultSeo, OrganizationJsonLd, LocalBusinessJsonLd} from 'next-seo';

// Styles
import '../styles/_globals.scss';

// Constants & Utils
import {META_INFO} from "../constants/metaConstants";
import {scrollToTop} from "../utils/helperFunctions";


function MyApp({ Component, pageProps }) {
    const router = useRouter();

    useEffect(() => {
        if (location.hash)
            router.replace(location.hash.replace('#!', '')).then();

        setCssViewportHeight();
        window.addEventListener('resize', () => {
            setCssViewportHeight();
        });

        window.dataLayer = window.dataLayer || [];

        function gtag() {
            window.dataLayer.push(arguments);
        }

        gtag('config', 'G-NFKLMLB9G1');
    }, []);

    useEffect(() => {
        if (process.browser)
            scrollToTop();
    }, [router.pathname]);


    const setCssViewportHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };


    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable = no" />
                <meta content={META_INFO.DEFAULT_URL} name="twitter:url"/>
                <meta content={META_INFO.DEFAULT_DESC} name="twitter:description"/>
                <meta content={META_INFO.DEFAULT_IMG} name="twitter:image"/>
            </Head>

            <DefaultSeo
                defaultTitle={META_INFO.DEFAULT_TITLE}
                title={META_INFO.DEFAULT_TITLE}
                description={META_INFO.DEFAULT_DESC}
                themeColor={META_INFO.THEME_COLOR}
                openGraph={{
                    url: META_INFO.DEFAULT_URL,
                    title: META_INFO.DEFAULT_TITLE,
                    description: META_INFO.DEFAULT_DESC,
                    images: [{
                        url: META_INFO.DEFAULT_IMG,
                        width: META_INFO.DEFAULT_IMG_WIDTH,
                        height: META_INFO.DEFAULT_IMG_HEIGHT,
                        alt: 'Og Image Alt',
                        type: 'image/jpeg',
                    }],
                    siteName: META_INFO.NAME,
                    type: 'website'
                }}
                twitter={{
                    handle: `@${META_INFO.TWITTER_HANDLE}`,
                    site: `@${META_INFO.TWITTER_HANDLE}`,
                    cardType: 'summary_large_image',
                }}
            />

            {/* Organization Schema Markup */}
            <OrganizationJsonLd
                name={META_INFO.NAME}
                url={META_INFO.DEFAULT_URL}
                id={META_INFO.DEFAULT_URL}
                logo={META_INFO.LOGO_IMG}
                sameAs={[
                    META_INFO.INSTAGRAM_URL,
                    META_INFO.TWITTER_URL,
                    META_INFO.LINKEDIN_URL
                ]}
                legalName={META_INFO.LEGAL_NAME}
                address={{
                    streetAddress: META_INFO.SCHEMA_ADDRESS.STREET,
                    addressLocality: META_INFO.SCHEMA_ADDRESS.LOCALITY,
                    addressRegion: META_INFO.SCHEMA_ADDRESS.REGION,
                    postalCode: META_INFO.SCHEMA_ADDRESS.POSTAL_CODE,
                    addressCountry: META_INFO.SCHEMA_ADDRESS.COUNTRY
                }}
                contactPoint={{
                    email: META_INFO.EMAIL
                }}
            />

            {/* LocalBusiness Schema Markup */}
            <LocalBusinessJsonLd
                type={'FinancialService'}
                id={META_INFO.DEFAULT_URL}
                name={META_INFO.NAME}
                description={META_INFO.LONG_DESC}
                address={{
                    streetAddress: META_INFO.SCHEMA_ADDRESS.STREET,
                    addressLocality: META_INFO.SCHEMA_ADDRESS.LOCALITY,
                    addressRegion: META_INFO.SCHEMA_ADDRESS.REGION,
                    postalCode: META_INFO.SCHEMA_ADDRESS.POSTAL_CODE,
                    addressCountry: META_INFO.SCHEMA_ADDRESS.COUNTRY
                }}
                geo={{
                    latitude: META_INFO.SCHEMA_GEO.LATITUDE,
                    longitude: META_INFO.SCHEMA_GEO.LONGITUDE
                }}
                sameAs={[
                    META_INFO.INSTAGRAM_URL,
                    META_INFO.TWITTER_URL,
                    META_INFO.LINKEDIN_URL
                ]}
                url={META_INFO.DEFAULT_URL}
                openingHours={{
                    opens: META_INFO.SCHEMA_OPENING_HOURS.OPENS_AT,
                    closes: META_INFO.SCHEMA_OPENING_HOURS.CLOSES_AT,
                    dayOfWeek: META_INFO.SCHEMA_OPENING_HOURS.DAY_OF_WEEK
                }}
            />

            {/* WebSite Schema Markup */}
            <Script id='schemaMarkupWebsite' type="application/ld+json" strategy='beforeInteractive' dangerouslySetInnerHTML={{ __html: `
                    {
                        "@context": "http://schema.org/",
                        "@type": "WebSite",
                        "name": "${META_INFO.NAME}",
                        "description": "${META_INFO.LONG_DESC}",
                        "url": "${META_INFO.DEFAULT_URL}",
                        "publisher": {
                            "@type": "Organization",
                            "name": "${META_INFO.NAME}",
                            "logo": {
                                "@type": "ImageObject",
                                "contentUrl": "${META_INFO.LOGO_IMG}"
                            }
                        }
                    }
                `}}/>


            <Component {...pageProps} />


            {/*Global site tag (gtag.js) - Google Analytics*/}
            <Script src="https://www.googletagmanager.com/gtag/js?id=G-NFKLMLB9G1"
                strategy="afterInteractive" />
            <Script id="google-analytics" strategy="afterInteractive">
                {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){window.dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'G-NFKLMLB9G1');
                  window.gtag = gtag;
                `}
            </Script>
        </>
    );
}

export default MyApp;
