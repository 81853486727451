export const META_INFO = {
    NAME: "NamasPay",

    LONG_DESC: "Discover India with the ultimate convenience of cashless UPI payments as a foreign traveller with NamasPay! Our secure and user-friendly mobile app is your trusted companion for hassle-free financial transactions while exploring the incredible culture and beauty of India.",

    LOGO_IMG: "https://www.namaspay.in/assets/icons/logoFullBlack.svg",

    THEME_COLOR: "#FAA629",

    DEFAULT_TITLE: "NamasPay - UPI payments for foreign travellers in India",
    DEFAULT_DESC: "Make cashless UPI payments in India as a foreign traveller or NRI with NamasPay for hassle-free financial transactions while exploring the incredible culture & beauty of India.",
    DEFAULT_URL: "https://namaspay.in/",
    DEFAULT_IMG: "https://namaspay.in/assets/images/meta_img.jpg",
    DEFAULT_IMG_WIDTH: 512,
    DEFAULT_IMG_HEIGHT: 512,

    FAQ_PAGE_TITLE: "FAQ - Frequently Asked Questions | NamasPay.in",
    FAQ_PAGE_URL: "https://namaspay.in/faq/",

    CONTACT_US_PAGE_TITLE: "Contact us for any queries | NamasPay.in",
    CONTACT_US_PAGE_URL: "https://namaspay.in/contact-us/",

    REFERRAL_PAGE_TITLE: "Get NamasPay | NamasPay.in",
    REFERRAL_PAGE_URL: "https://namaspay.in/referral/",

    PRIVACY_POLICY_PAGE_TITLE: "Privacy Policy | NamasPay.in",
    PRIVACY_POLICY_PAGE_URL: "https://namaspay.in/privacy-policy/",

    TERMS_PAGE_TITLE: "Terms of Service | NamasPay.in",
    TERMS_PAGE_URL: "https://namaspay.in/terms-of-service/",

    GRIEVANCE_POLICY_PAGE_TITLE: "Grievance Policy | NamasPay.in",
    GRIEVANCE_POLICY_PAGE_URL: "https://namaspay.in/grievance-policy/",

    NOT_FOUND_PAGE_TITLE: "Page Not Found - Error 404 | NamasPay.in",
    NOT_FOUND_PAGE_URL: "https://namaspay.in/404/",

    SERVER_ERROR_PAGE_TITLE: "Something went wrong | NamasPay.in",
    SERVER_ERROR_PAGE_URL: "https://namaspay.in/500/",

    APP_COPIES_PAGE_TITLE: "App copies | NamasPay.in",
    APP_COPIES_PAGE_URL: "https://namaspay.in/app-copies/",

    EMAIL: "care@namaspay.in",
    KEYWORDS: "UPI, Foreign Traveller, Tourist",
    LEGAL_NAME: "Trio Tech Solutions Private Limited",

    TWITTER_HANDLE: "namaspay",

    INSTAGRAM_URL: "https://www.instagram.com/namaspay.in/",
    TWITTER_URL: "https://twitter.com/namaspay",
    LINKEDIN_URL: "https://www.linkedin.com/company/namaspay/",

    SCHEMA_ADDRESS: {
        STREET: "L376 / A, 14th B Cross Rd, Sector 6, HSR Layout",
        LOCALITY: "Bengaluru",
        REGION: "Karnataka",
        POSTAL_CODE: "560102",
        COUNTRY: "IN"
    },
    SCHEMA_GEO: {
        LATITUDE: "12.914612266121454",
        LONGITUDE: "77.63243716681276"
    },
    SCHEMA_OPENING_HOURS: {
        DAY_OF_WEEK: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
        OPENS_AT: "10:30",
        CLOSES_AT: "20:00"
    },
    SCHEMA_SLOGAN: "Cashless UPI payments for foreign travellers in India",
};
